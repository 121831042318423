<template>
  <Page
    has-actions
    :subtitle="subtitle"
    :loading="blog_loading"
  >
    <b-switch
      v-if="blog"
      slot="actions"
      :disabled="action == 'create'"
      :value="blog.active"
      type="is-success"
      @input="activateOrDesactivateBlog"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card>
      <div
        v-if="blog_loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="blog_loading"
          :is-full-page="false"
        />
      </div>
      <BlogForm
        v-else
        :index="index"
        :blog="blog"
        :sites="sites"
        :categories="categories"
        :action="action"
      />
    </Card>
  </Page>
</template>

<script>
import BlogForm from "@/components/forms/Blog/Index";

export default {
  components: {
    BlogForm
  },
  data() {
    return {
      subtitle: "",
      blog_loading: true,
      action: "",
      blog: {},
      index: -1,
      sites: [],
      categories: []
    };
  },
  created() {
    if (this.$route.params.blog_id) {
      this.updateTitle(this.$t("edit_noun"));
      this.action = "update";
      this.$axios
        .get("blog/" + this.$route.params.blog_id)
        .then(res => {
          this.blog = res.data.blog;
          this.subtitle = res.data.title;
          this.sites = res.data.filters.sites;
          this.categories = res.data.filters.categories;
          this.index = 1;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.blog_loading = false));
    } else {
      this.updateTitle(this.$t("new_article"));
      this.action = "create";
      this.$axios
        .get("blog/0")
        .then(res => {
          this.sites = res.data.filters.sites;
          this.categories = res.data.filters.categories;
          this.index = -1;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.blog_loading = false));
    }
  },
  methods: {
    activateOrDesactivateBlog(){
      if (this.blog.active) {
        this.$axios
        .delete("blog/" + this.blog.id)
          .then(() => {
            this.blog.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch(e => this.clientError(e));
      } else {
        this.$axios
        .patch("blog/" + this.blog.id + "/restore")
          .then(() => {
            this.blog.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch(e => this.clientError(e));
      }
    },
  }
};
</script>
